.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 520px;
  margin: 0 auto;
}
.container header {
  padding-top: 20px;
}
.container p {
  color: #858789
}
.container footer {
  height: 75px;
  font-size: .8rem;
  color: #c0c0c0
}
.container .center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
}